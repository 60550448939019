import { render, staticRenderFns } from "./MarcaHome.vue?vue&type=template&id=12342bba"
import script from "./MarcaHome.vue?vue&type=script&lang=js"
export * from "./MarcaHome.vue?vue&type=script&lang=js"
import style0 from "./MarcaHome.vue?vue&type=style&index=0&id=12342bba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports